import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
       
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n  .hydroboots .faq__item__name { color: #77787b; }\n  .hydroboots .faq__item__content { color:#77787b; }\n  .hydroboots .faq__item__short .btn__more { background-color: #cfddf2; }\n  .hydroboots .faq__item__short .btn__less { background-color: #cfddf2; }\n"
          }}
        />
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/index.php/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/bad-piekna/" className="section">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/see-whats-possible/"
                      className="link"
                    >
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/index.php/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/index.php/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/index.php/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/index.php/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>Pielęgnacja ust</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/bad-piekna/">Bądź piękna</a>{" "}
                  <span>&gt;</span>
                  Pielęgnacja ust
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/site/index.php/bad-piekna/test-skory/" className="link">
                Test skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/ogolnopolskie-badanie-skory/"
                className="link"
              >
                Badanie Skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/sucha-skora/"
                className="link"
              >
                Sucha skóra
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-twarzy/"
                className="link"
              >
                Pielęgnacja twarzy
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-ust/"
                className="current"
              >
                Pielęgnacja ust
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-stop/"
                className="link"
              >
                Pielęgnacja stóp
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-ciala/"
                className="link"
              >
                Pielęgnacja ciała i regeneracja skóry
              </a>
              <a
                href="/site/index.php/bad-piekna/pielegnacja-doni/"
                className="link"
              >
                Pielęgnacja dłoni
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="bg usta1">
                  <div className="text full">
                    <h2>
                      Usta są najczęściej zaniedbywaną częścią naszego ciała
                    </h2>
                    <p>
                      Wynika to na ogół z braku wiedzy na temat ich skutecznej
                      pielęgnacji.
                      <br />
                      Tymczasem skóra ust jest szczególnie delikatna i
                      potrzebuje wyjątkowej ochrony. Zadbane, błyszczące usta,
                      <br />w przeciwieństwie do spierzchniętych warg, wyglądają
                      pięknie i są oznaką zdrowego stanu skóry.
                      <br />
                      <br />
                    </p>
                  </div>
                  <div className="text left">
                    <p>
                      Skóra warg jest wprawdzie zbudowana z naskórka i skóry
                      właściwej, jednak warstwa rogowa ust jest znacznie cieńsza
                      niż na innych częściach ciała. Ponadto wargi są pozbawione
                      zarówno melaniny, która chroniłaby je przed szkodliwym
                      wpływem promieniowania UV, jak i gruczołów łojowych, które
                      zapewniają właściwe nawilżenie.
                      <br />
                      Ekspozycja na słońce, silny wiatr lub skrajne temperatury
                      przyczyniają się&nbsp;do pierzchnięcia ust. Do innych
                      przyczyn suchości warg zalicza się ich świadome lub
                      nieświadome oblizywanie, a także uczulenie na np. pastę do
                      zębów, która może działać drażniąco na skórę.&nbsp;
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg usta2">
                  <div className="text right">
                    <h2>Odpowiednie nawilżanie</h2>
                    <p>
                      Bez względu na przyczynę dolegliwości, spierzchnięte usta
                      wymagają{" "}
                      <span className="bold">
                        nawilżenia, regeneracji oraz ochrony przed niekorzystnym
                        działaniem czynników zewnętrznych.
                      </span>
                      <br />
                      <br /> W skład linii produktów NEUTROGENA<sup>®</sup> do
                      pielęgnacji ust wchodzą sztyfty z filtrami SPF i balsam
                      zawierające Formułę Norweską, która przynosi{" "}
                      <span className="bold">
                        natychmiastową ulgę i przyspiesza intensywną
                        regenerację.
                      </span>{" "}
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </section>
              <div className="fb">
                <div
                  style={{
                    width: "250px",
                    height: "auto",
                    float: "left"
                  }}
                >
                  <iframe
                    src="//www.facebook.com/plugins/like.php?href=https://neutrogena.com.pl/site/index.php/index.php/bad-piekna/pielegnacja-ust/&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                    scrolling="no"
                    frameBorder="0"
                    style={{
                      border: "none",
                      overflow: "hidden",
                      width: "200px",
                      height: "21px"
                    }}
                    allowTransparency="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/index.php/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/index.php/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/index.php/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/index.php/produkty/">
                  zobacz produkty neutrogena
                </a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/" className="logo2" />
                <a href="/site/index.php/nota-prawna/">Nota Prawna</a>
                <a href="/site/index.php/polityka-prywatnosci/">
                  Polityka Prywatności
                </a>
                <a href="/site/index.php/cookies/">Polityka cookies</a>
                 <a href="/site/index.php/kontakt/">Zachęcamy do kontaktu</a>              
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z
                o. o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 25/10/2013
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
